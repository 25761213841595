import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TYPOGRAPHY } from '@junglescout/styles-library'
import { ImageStack, Separator } from '@junglescout/edna'

import { toLineBreakFriendly } from 'helpers/strings'
import { formatImageResolution } from 'helpers/images'

import { TruncatedTextWithTooltip } from 'ui_elements/TruncatedTextWithTooltip/TruncatedTextWithTooltip'
import { AsinActionsHoverCard } from 'ui_elements/AsinActionsHoverCard/AsinActionsHoverCard'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'

const AsinBrandWrapper = styled.div`
  ${TYPOGRAPHY.bodyXs};
  color: ${props => props.theme.colors.grey600};
  display: flex;
  align-items: center;
`
const Title = styled(TruncatedTextWithTooltip)`
  ${TYPOGRAPHY.bodySm};
`
const TitleSection = styled.div`
  width: 100%;
`
const Brand = styled.div`
  /* without this the tooltip for no data gets squished */
  ${props =>
    props.hasBrand &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:  ${props.leftOffset ? '138px' : '195px'};
  `}
`
const Spacer = styled.div`
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  content: '';
  flex-grow: 3;
`

export const ProductTitle = props => {
  const { product, isProductVariant, leftOffset } = props
  const { name, imageUrl, brand, variantAsinsCount } = product

  const formattedImage = useMemo(() => formatImageResolution(imageUrl), [
    imageUrl
  ])
  const productName = useMemo(() => toLineBreakFriendly(name), [name])

  const badgeNumber = variantAsinsCount > 15 ? '+15' : variantAsinsCount
  const showStack = !isProductVariant && variantAsinsCount > 0

  return (
    <div>
      {leftOffset && <Spacer />}
      <AsinActionsHoverCard
        imageUrl={imageUrl}
        asin={product.asin}
        title={productName}
        marketplace={product.country}
        brand={brand}
        trigger={
          <div style={{ display: 'flex', gap: '8px' }}>
            <ImageStack
              src={formattedImage}
              alt={name || 'n/a'}
              badgeNumber={
                !isProductVariant && badgeNumber > 0 ? badgeNumber : null
              }
              showStack={showStack}
              size="small"
              showOnHover={false}
            />
            <TitleSection>
              <Title numOfLines={1} disableTooltip>
                {productName}
              </Title>
              <AsinBrandWrapper>
                {product.asin}
                <Separator
                  orientation="vertical"
                  height="18px"
                  margin="0 6px"
                />
                <Brand
                  title={brand}
                  hasBrand={!!brand}
                  leftOffset={isProductVariant}>
                  {brand || (
                    <NoDataTooltip
                      content={DATABASE_TOOLTIPS.nullProductData.brand}
                      size="medium"
                      side="right"
                    />
                  )}
                </Brand>
              </AsinBrandWrapper>
            </TitleSection>
          </div>
        }
        preventPropagation={false}
        overrideTriggerClick={false}
        trackingProps={{ location: 'Product Database' }}
      />
    </div>
  )
}

ProductTitle.defaultProps = {
  isProductVariant: false,
  leftOffset: false
}

ProductTitle.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  leftOffset: PropTypes.bool,
  isProductVariant: PropTypes.bool
}
